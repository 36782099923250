
import { Component, Vue } from "vue-property-decorator";
import LayoutDefault from "@/layouts/LayoutDefault.vue";
import TitleText from "@/components/TitleText.vue";

@Component<any>({
  metaInfo() {
      return {
        title: "Leighton's Privacy Policy - Tech, Talent, Together",
        meta: [{
          vmid: 'description',
          name: 'description',
          content: "This privacy notice aims to give you information on how Leighton collects and processes your personal data.",
        }]
    }
  },
  components: {
    LayoutDefault,
    TitleText
  }
})
export default class PrivacyPolicy extends Vue {
  private lastUpdated = "March 29th, 2021";

  private titleText1 = {
    customClass: "text-left mt-md",
    title: "Introduction",
    textContent: `
      Welcome to the Leighton Corpoaration Limited’s (“Leighton”, “we”, “us”, or “our”) privacy notice.
      <br><br>
      Leighton respects your privacy and is committed to protecting your personal data. This privacy notice will inform you as to how we look after your personal data and tell you about your privacy rights and how the law protects you.
      <br><br>
      Please also use our Glossary to understand the meaning of some of the terms used in this privacy notice.
      
    `
  };

  private titleText2 = {
    customClass: "text-left mt-md",
    title: "Purpose of this privacy notice",
    textContent: `  
      This privacy notice aims to give you information on how Leighton collects and processes your personal data. We may collect and process your personal data when you enquire or purchase a product or service from us, when you correspond with us i.e. face to face, by telephone or by email and when using our website, including any data you may provide through our website i.e. by filling in forms on our website.
      <br><br>
      It is important that you read this privacy notice so that you are fully aware of how and why we are using your data. This privacy notice supplements the other notices we may issue from time to time and is not intended to override them. 
    `
  };

  private titleText3 = {
    customClass: "text-left mt-md",
    title: "Controller",
    textContent: ` 
      Leighton is the controller and responsible for your personal data.<br>
      We have appointed a data privacy manager who is responsible for overseeing questions in relation to this privacy notice. If you have any questions about this privacy notice, including any requests to exercise your legal rights, please contact the data privacy manager using the details set out below.
    `
  };

  private titleText4 = {
    customClass: "text-left mt-md",
    title: "Contact details",
    textContent: `  
      Our full details are:
      <br><br>
      Full name of legal entity: Leighton Corporation Limited<br>
      Email address: <a href="mailto:privacy@leighton.com" target="_blank" class="font-weight-bold emphasise--dark-green">privacy@leighton.com</a><br>
      Postal address: The Core, Bath Lane, Newcastle Helix, Newcastle Upon Tyne, NE4 5TF
      <br><br>
      You have the right to make a complaint at any time to the Information Commissioner's Office (“ICO”), the UK supervisory authority for data protection issues. We would, however, appreciate the chance to deal with your concerns before you approach the ICO so please contact us in the first instance.
      <br><br>
      The ICO’s address:<br>
      Information Commissioner’s Office<br>
      Wycliffe House<br>
      Water Lane<br>
      Wilmslow<br>
      Cheshire<br>
      SK9 5AF
      <br><br>
      Helpline number: <a class="font-weight-bold emphasise--dark-green" target="_blank" href="tel:+443031231113">0303 123 1113</a><br>
      ICO website: <a class="font-weight-bold emphasise--dark-green" href="https://www.ico.org.uk" target="_blank">https://www.ico.org.uk</a>
    `
  };

  private titleText5 = {
    customClass: "text-left mt-md",
    title:
      "Changes to the privacy notice and your duty to inform us of changes",
    textContent: `
      It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us.
    `
  };

  private titleText6 = {
    customClass: "text-left mt-md",
    title: "Third-party links",
    textContent: ` 
      This website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage you to read the privacy notice of every website you visit.
    `
  };

  private titleText7 = {
    customClass: "text-left mt-md",
    title: "The data we collect about you",
    textContent: `  
      Personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data).<br>
      We may collect, use, store and transfer different kinds of personal data about you which we have grouped together follows:
      <ul class="ml-4 bullet-list">
        <li><strong>Identity Data</strong> includes first name, maiden name, last name, marital status, title and gender.</li>
        <li><strong>Contact Data</strong> includes your postal address, email address and telephone numbers.</li>
        <li><strong>Transaction Data</strong> includes details of products and services you have purchased from us.</li>
        <li><strong>Technical Data</strong> includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform and other technology on the devices you use to access this website.</li>
        <li><strong>Usage Data</strong> includes information about how you use our website, products and services.</li>
        <li><strong>Marketing and Communications Data</strong> includes your preferences in receiving marketing from us and our third parties and your communication preferences.</li>
      </ul>
      <br><br>
      We also collect, use and share Aggregated Data such as statistical or demographic data for any purpose. Aggregated Data may be derived from your personal data but is not considered personal data in law as this data does not directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific website feature. However, if we combine or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this privacy notice.
      <br><br>
      We do not collect any Special Categories of Personal Data about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health and genetic and biometric data). Nor do we collect any information about criminal convictions and offences.
    `
  };

  private titleText8 = {
    customClass: "text-left mt-md",
    title: "If you fail to provide personal data",
    textContent: `
      Where we need to collect personal data by law, or under the terms of a contract we have with you and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you (for example, to provide you with goods or services). In this case, we may have to cancel a product or service you have with us but we will notify you if this is the case at the time.
    `
  };

  private titleText9 = {
    customClass: "text-left my-md",
    title: "How is your personal data collected?",
    textContent: `
      We use different methods to collect data from and about you including through:
      <ul class="ml-4 bullet-list">
        <li><strong>Direct interactions.</strong> You may give us your Identity, Contact and Transaction Data by filling in forms or by corresponding with us by post, phone, email or otherwise.</li>
        <li><strong>Automated technologies or interactions.</strong> As you interact with our website, we may automatically collect Technical Data about your equipment, browsing actions and patterns. We collect this personal data by using cookies and other similar technologies. Please see our <a class="emphasise--dark-green font-weight-bold" href="#">cookie policy</a> for further details.</li>
      </ul>
    `
  };
}
